<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系統管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/sysManagement/sysUserManagement' }">用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <div v-if="!showEdit">
        <div class="title">用户详情</div>
        <div class="detail-container">
          <el-row class="detail-row">
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">用户编号</div>
                <div class="detail-item__val">{{ userDetail.userId }}</div>
              </div>
            </el-col>
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">注册时间</div>
                <div class="detail-item__val">{{ userDetail.createTime }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row class="detail-row">
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">用户账号</div>
                <div class="detail-item__val">{{ userDetail.account }}</div>
              </div>
            </el-col>
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">用户昵称</div>
                <div class="detail-item__val">
                  {{ userDetail.userName || "-" }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="detail-row">
            <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">关联角色</div>
                <div class="detail-item__val">{{ userDetail.name || "-" }}</div>
              </div>
            </el-col>
            <!-- <el-col :span="6" style="min-width: 600px">
              <div class="detail-item">
                <div class="detail-item__title">所属部门</div>
                <div class="detail-item__val">{{ userDetail.deptName }}</div>
              </div>
            </el-col> -->
          </el-row>

        </div>

        <div class="btn-group">
          <div class="btn edit" @click="edit">编辑</div>
          <div class="btn reset" @click="resetPwd">重置密码</div>
          <div class="btn unlock" @click="unLock">用户解锁</div>
          <div class="btn del" @click="delUser">删除</div>
          <div class="btn back" @click="back">返回</div>
        </div>

        <el-dialog v-model="dialogFormVisible" title="重置密码" :width="'500px'">
          <el-form ref="ruleFormRef" :model="ruleForm" status-icon :rules="pwdRules" label-width="120px" class="demo-ruleForm">
            <el-form-item label="新密码" prop="pass">
              <el-input v-model="ruleForm.pass" type="password" autocomplete="off" style="width: 260px" />
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input v-model="ruleForm.checkPass" type="password" autocomplete="off" style="width: 260px" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm()">提交</el-button>
              <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>

      </div>

      <div v-else>
        <div class="title">修改用户信息</div>
        <div class="container" style="width: 500px">
          <el-form ref="userForm" class="user-form" :model="userEdit" :rules="rules" label-width="120px">
            <el-form-item label="用户账号">
              {{ userEdit.account }}
            </el-form-item>
            <el-form-item label="用户昵称">
              <el-input v-model.trim="userEdit.userName" maxlength="100"></el-input>
            </el-form-item>
            <!-- <el-form-item label="用户密码" prop="password">
              <el-input v-model.trim="userEdit.password" maxlength="100"></el-input>
            </el-form-item> -->
            <el-form-item label="关联角色" prop="roleIdList">
              <el-select v-model="userEdit.roleIdList" multiple placeholder="请选择" style="width: 100%">
                <el-option v-for="item in options" :key="item.roleId" :label="item.name" :value="item.roleId">
                </el-option>
              </el-select>
            </el-form-item>

          </el-form>
        </div>

        <div class="btn-group">
          <div class="btn confirm" @click="confirm">确认修改</div>
          <div class="btn back" @click="backLast">返回</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  ref
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { getBackUserDetail, getAllBackRole, updateBackUser, delBackUser, resetUserPwd, unlockUser } from "../../../api/sysManagement/user";
import md5 from 'js-md5';
export default {
  setup() {
    const userForm = ref();
    const router = useRouter();
    const route = useRoute();
    const ruleFormRef = ref();
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (state.ruleForm.checkPass !== '') {
          if (!ruleFormRef.value) return
          ruleFormRef.value.validateField('checkPass', () => null)
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== state.ruleForm.pass) {
        callback(new Error("两次密码不匹配！"))
      } else {
        callback()
      }
    }
    const state = reactive({
      showEdit: false,
      options: [],
      warehouseList: [],
      instructionList: [],
      userDetail: {},
      userEdit: {
        account: "",
        userName: "",
        roleIdList: [],
        relatedWarehouse: [],
        instructionWarehouse: [],
        dno: "",
      },

      formatCreateTime: "",
      rules: {
        roleIdList: [
          { required: true, message: "请选择关联角色", trigger: "change" },
        ],
        relatedWarehouse: [
          {
            type: "array",
            required: true,
            message: "请选择关联操作仓",
            trigger: "change",
          },
        ],
      },
      dialogFormVisible: false,
      formLabelWidth: 120,

      userInfo: {},

      ruleForm: {
        pass: '',
        checkPass: '',
      },
      pwdRules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
      }
    });

    onMounted(() => {
      let data = {
        userId: route.query.id,
      };
      getBackUserDetail(data).then((res) => {
        if (res.code === '200') {
          const { data } = res;
          state.userDetail = data;
        }
      }).catch((err) => {

      });

      getRoleList();
    });

    const getRoleList = () => {
      getAllBackRole().then((res) => {
        if (res.resultState === "200") {
          state.options = res.data;
        }
      }).catch((err) => {

      });
    };

    const edit = () => {
      state.showEdit = true;
      state.userEdit = JSON.parse(JSON.stringify(state.userDetail));
      console.log(state.userEdit);
      let roleNameList = state.userEdit.name
        ? state.userEdit.name.split("/")
        : [];
      state.userEdit.roleIdList = [];
      state.options.forEach((item) => {
        if (roleNameList.indexOf(item.name) > -1) {
          state.userEdit.roleIdList.push(item.roleId);
        }
      });

    };

    const backLast = () => {
      state.showEdit = false;
    };

    const back = () => {
      router.push({ path: "/sysManagement/sysUserManagement" });
    };

    const delUser = () => {
      let params = {
        userId: route.query.id,
      };
      delBackUser({ data: params }).then((res) => {
        if (res.code === "200") {
          ElMessage({
            message: `${res.msg}`,
            type: "success",
          });
          router.push({ path: "/sysManagement/sysUserManagement" });
        }
      }).catch((err) => {

      });
    };

    const unLock = () => {
      let params = {
        account: state.userDetail.account
      }
      unlockUser(params).then((res) => {
        if (res.resultState === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: "success",
          });
        }
      }).catch((err) => {

      });
    };

    const resetPwd = () => {
      state.dialogFormVisible = true;
    };



    const submitForm = () => {
      ruleFormRef.value.validate((valid) => {
        if (valid) {
          //调用重置密码
          resetPwdJK();
        } else {
          console.log('error submit!')
          return false
        }
      })
    }

    const resetForm = () => {
      ruleFormRef.value.resetFields();
    }

    const resetPwdJK = () => {
      let params = {
        userId: route.query.id,
        password: md5(state.ruleForm.pass)
      }
      resetUserPwd(params).then((res) => {
        if (res.code === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: "success",
          });
          state.dialogFormVisible = false;
        }
      }).catch((err) => {

      });
    }

    const confirm = () => {
      let data = {
        account: state.userEdit.account,
        accountSetId: "",
        adminCustomer: "",
        avatar: "",
        certificateId: "",
        corpId: "",
        createMan: "",
        createManName: "",
        createTime: "",
        delFlag: "",
        email: "",
        isAdmin: "",
        lockState: "",
        password: "",
        phone: "",
        position: "",
        qq: "",
        remarks: "",
        roleId: state.userEdit.roleIdList.toString(),
        salt: "",
        sex: "",
        type: "",
        updateMan: "",
        updateManName: "",
        updateTime: "",
        userId: route.query.id || "",
        userName: state.userEdit.userName,
        userNum: "",
        wechat: ""
      };

      userForm.value.validate((valid) => {
        if (valid) {
          updateBackUser(data).then((res) => {
            if (res.code === "200") {
              ElMessage({
                message: `${res.msg}`,
                type: "success",
              });

              state.userDetail = JSON.parse(JSON.stringify(state.userEdit));
              let roleNameList = [];
              state.options.forEach((item) => {
                if (state.userEdit.roleIdList.indexOf(item.roleId) > -1) {
                  roleNameList.push(item.name);
                }
              });
              console.log(roleNameList)
              state.userDetail.name = roleNameList.join("/"); //角色名称

              state.showEdit = false;
            }
          }).catch((err) => {

          });
        }
      });
    };

    return {
      ...toRefs(state),
      edit,
      backLast,
      back,
      resetPwd,
      unLock,
      delUser,
      confirm,
      userForm,
      submitForm,
      ruleFormRef,
      resetForm
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
}
.detail-container {
  padding: 30px;
}
.detail-row {
  height: 50px;
}
.detail-item {
  display: flex;
  align-items: baseline;
  &__title {
    width: 100px;
  }
  &__val {
    width: 800px;
    word-break: break-all;
  }
}
.btn-group {
  display: flex;
  padding: 30px;
  .btn {
    width: 100px;
    text-align: center;
    padding: 6px 0;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    &.edit {
      background: rgb(56, 162, 138);
      color: #fff;
    }
    &.reset {
      background: rgb(241, 222, 146);
      margin: 0 25px;
    }
    &.unlock {
      background: #409eff;
      color: #fff;
    }
    &.del {
      background: rgb(255, 127, 127);
      color: #fff;
      margin: 0 25px;
    }
    &.back {
      border: 1px solid #ddd;
    }
    &.confirm {
      background: rgb(56, 162, 138);
      color: #fff;
      margin: 0 25px;
    }
  }
}
.container {
  padding: 20px;
  &__item {
    display: flex;
    align-items: center;
    &.item {
      margin-top: 15px;
    }
  }
  &__title {
    width: 100px;
  }
  &__input {
    width: 280px;
  }
  .dno-state {
    color: #38a28a;
    font-weight: bold;
  }
  .re-check {
    background: #38a28a;
    color: #fff;
  }
  .user-info-form ::v-deep .el-form-item {
    margin-bottom: 0;
  }
}
</style>
